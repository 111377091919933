@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

* {
    line-height: 2;
    margin: 0;
}

/*--------------------
| Body
--------------------*/
html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    background: linear-gradient(0, #202239, #8595AC);
    font-size: 16px;
}

::selection {
    background: #CDD4DE;
}

/*--------------------
| Page
--------------------*/
.page {
    @include center;
    width: 1000px;
    height: 650px;
    z-index: 1;
    font-family: Roboto, sans-serif;
    background: #0D0C1E;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .3);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        width: 100%;
        height: 100%;
    }
}

/*--------------------
| Header
--------------------*/
header {
    margin-top: 20px;

    .logo {
        text-align: center;

        svg {
            width: 170px;
            height: 130px;
        }
    }
}

/*--------------------
| Content
--------------------*/
.content {
    height: 320px;
    margin-bottom: 20px;
    color: #CDD4DE;
    text-shadow: 0px 0px 7px rgba(86, 92, 115, 0.29);
    text-align: center;

    @media screen and (max-width: 1000px) {
        //justify-content: flex-start;
    }

    h1 {
        font-weight: 900;
        font-size: 9rem;
        line-height: 1;
        opacity: 0.6;

        @media screen and (max-width: 1000px) {
            margin: 0;
        }
    }

    h2 {
        font-weight: 700;
        font-size: 34px;
        margin-bottom: 6px;
        opacity: 0.9;

        @media screen and (max-width: 1000px) {
            font-size: 2rem;
        }
    }

    p {
        font-weight: 300;
        font-size: 14px;
        opacity: 0.7;
        margin-bottom: 30px;

        @media screen and (max-width: 1000px) {
            font-size: 14px;
        }
    }

    a {
        color: #bac4d2;
        display: inline-block;
        font-weight: 300;
        font-size: 12px;
        text-transform: uppercase;
        border: 1px solid #CDD4DE;
        padding: 8px 14px;
        border-radius: 4px;
        opacity: 0.4;
        cursor: pointer;
    }
}

/*--------------------
| Image
--------------------*/
img {
    position: absolute;
    top: 0;
    left: 0;
    width: 110%;
    height: 110%;
    z-index: -1;
    transform: scale(1.1);

    @media screen and (max-width: 1000px) {
        object-fit: cover;
        background-size: cover;
    }
}
